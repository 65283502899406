.readonly-value {
  font-size: 11px;
  margin-bottom: 18px;
}

.cj-green {
  color: #00af66;
}

.password-icon {
  position: absolute;
  font-size: 30px;
}

.visibility-icon {
  position: absolute;
  right: 8px;
}

.input-container {
  position: relative;
}

.spacer-24px {
  height: 24px;
  margin: auto;
}

.nav-content {
  padding: 24px;
}

.user-profile-content .panel {
  box-shadow: none;
}

.user-profile-content .panel-body {
  padding: 0 24px 24px 24px;
  border-color: #d8dcdf;
}

.user-profile-content .panel-footer {
  padding: 24px;
}

.user-profile-content input {
  min-width: 320px;
  width: 30%;
  margin-right: 8px;
  margin-bottom: 16px;
}

.user-profile-content label {
  width: 100%;
}

.user-profile-content h5 {
  margin: 0;
  margin-top: 24px;
  padding-bottom: 8px;
}

.user-profile-content button {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0;
}

.user-profile-content .vs-text-btn {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
}

.user-profile-content form {
  margin-bottom: 24px;
  margin-top: 24px;
}

.user-profile-content .vs-text-type-light {
  margin: 0;
  display: block;
  font-size: 13px;
}
