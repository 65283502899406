.preferences-content .panel {
  box-shadow: none;
}

.preferences-content .panel-body {
  padding: 0px 24px 24px 24px;
  border-color: #d8dcdf;
}

.preferences-content .panel-footer {
  padding: 24px;
}

.preferences-content input {
  vertical-align: middle;
}

.preferences-content .vs-default-react-select {
  min-width: 320px;
  width: 30%;
}

.preferences-content h5 {
  margin: 0;
  margin-top: 24px;
  padding-bottom: 8px;
}

.preferences-content button {
  padding-left: 24px;
  padding-right: 24px;
  margin: 0px;
}

.preferences-content .vs-text-btn {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
}

.preferences-content form {
  margin-bottom: 24px;
  margin-top: 24px;
}

.preferences-content .vs-text-type-light {
  margin: 0px;
  display: block;
  font-size: 13px;
}
